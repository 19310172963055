import React, { FC, useEffect, useState } from "react";
import { CloseIcon, DownloadIcon, FilterIcon, ManageColumnsIcon, RefreshIcon } from "../../../svg/IconCollection";
import { ReportConstants, ReportsFilterConstants } from "../../../../helper/Constants";
import { Drawer } from "./Drawer";
import { Filters } from "./Filters";
import { ColorConstants } from "../../../../assets/custom/colors";
import { FilterType, MethodOfTransfer } from "../../../../core/common/enums";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import {
    IDateValidationObject,
    IFilterCondition,
    IFilterModel,
    initialDateValidationObject,
    initialFilterValues,
    ISenderList,
} from "../../../../core/viewModels/report/reportViewModel";
import {
    createFilter,
    deleteFilter,
    getAllFilters,
    updateFilter,
} from "../../../../actionCreators/reportsActionCreator";
import { AppNotifier } from "../../../../helper/AppNotifier";
import {
    initialSentItemsRequestObject,
    IReportItemsHistoryRequestModel,
} from "../../../../core/viewModels/report/SentItemsHistoryModel";
import { getSentItemsHistoryMetrics } from "../../../../actionCreators/sentItemsHistoryActionCreator";
import { getReceivedItemsHistoryMetrics } from "../../../../actionCreators/receivedItemsHistoryActionCreator";
import {
    fetchReceivedMessageHistorySettings,
    fetchRequestHistorySettings,
    fetchSentMessageHistorySettings,
} from "../../../../actionCreators/adminSettingsActionCreators";
import { logger } from "../../../../oidcClient/authProvider";
import { appInsightsData } from "../../../../helper/AppInsightsData";
import { isAdminUser } from "../../../../helper/HelperFunctions";
import { AutomationIdConstants } from "../../../../helper/AutomationConstants";
import {
    IReceivedItemsHistorySettingsValues,
    IRequestHistorySettingsValues,
    ISentItemsHistorySettingsValues,
} from "../../../../reducers/adminSettingsReducer";
import { getDocumentHistoryMetrics } from "../../../../actionCreators/documentRequestHistoryActionCreator";
import { ToasterMessages } from "../../../../helper/ToasterMessages";
import { isDateValid } from "../../../../helper/DateHelperFunctions";

interface IReportHeader {
    title: string;
    sendersList: ISenderList[];
    filterType: FilterType;
    selectedFilter: IFilterModel;
    prevSelectedFilter: IFilterModel;
    setPrevSelectedFilter:React.Dispatch<React.SetStateAction<IFilterModel>>;
    fetchReportsData: (payload?: IReportItemsHistoryRequestModel, callback?: () => void) => void;
    setSelectedFilter: React.Dispatch<React.SetStateAction<IFilterModel>>;
    handleColumnDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    handleDownload: () => void;
    isDownloading: boolean;
    setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const ReportHeader: FC<IReportHeader> = props => {
    const {
        title,
        sendersList,
        filterType,
        selectedFilter,
        prevSelectedFilter,
        setPrevSelectedFilter,
        fetchReportsData,
        setSelectedFilter,
        handleColumnDrawer,
        handleDownload,
        isDownloading,
        setCurrentPageNumber,
    } = props;
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);
    const userProfile = useSelector((state: ApplicationState) => state.userProfile);
    const filterStore = useSelector((state: ApplicationState) => state.reportsState.filters);
    const user = useSelector((state: ApplicationState) => state.userAuth.user);
    const emails = sendersList.map(item => item.email);
    const [dateValidation, toggleDateValidation] = useState<IDateValidationObject>(initialDateValidationObject);
    const [sentDateAccordionValidation, setSentDateAccordionValidation] = useState(false);
    const [activityDateAccordionValidation, setActivityDateAccordionValidation] = useState(false);
    const [invalidSentDateValidation, setInvalidSentDateValidation] = useState(false);
    const [invalidActivityDateValidation, setInvalidActivityDateValidation] = useState(false);
    const [enableClearFilter, setEnableClearFilter] = useState(false);
    const [resetFilterEnable, setResetFilterEnable] = useState(false);
    const [filterName, setFilterName] = useState(selectedFilter.name);
    const showCustomFilterText = selectedFilter && selectedFilter.id === 0 && !filterName;    
    const dispatch = useDispatch();

    const getSettings = () => {
        const adminSettings = useSelector((state: ApplicationState) => state.adminSettingsState);

        switch (filterType) {
            case FilterType.SIH:
                return adminSettings.messageHistorySettings.sentItemsSettings;
            case FilterType.RIH:
                return adminSettings.messageHistorySettings.receivedItemsSettings;
            case FilterType.DRH:
                return adminSettings.requestHistorySettings;
        }
    };

    const getMetricsData = () => {
        if (filterType == FilterType.SIH) {
            return useSelector((state: ApplicationState) => state.sentItemsReportState.metricsData);
        } else if (filterType == FilterType.RIH) {
            return useSelector((state: ApplicationState) => state.receivedItemsReportState.metricsData);
        } else {
            return useSelector((state: ApplicationState) => state.documentRequestReportState.metricsData);
        }
    };

    const showMetrics = (settings: any) => {
        if (isAdminUser(user)) return true;
        if (filterType == FilterType.SIH) {
            const data = settings as ISentItemsHistorySettingsValues;
            return data.isUserAllowedToViewTransferOfAllUsers;
        } else if (filterType == FilterType.RIH) {
            const data = settings as IReceivedItemsHistorySettingsValues;
            return data.isUserAllowedToViewTransferOfAllUsers;
        } else if (filterType == FilterType.DRH) {
            const data = settings as IRequestHistorySettingsValues;
            return data?.isUserAllowedToViewDocumentRequestSentByAllUsers;
        }
    };
    const metricsData = getMetricsData();
    const settings = getSettings();
    const showMetricsData = showMetrics(settings);
    useEffect(() => {
        const filter =
            filterStore?.data &&
            Array.isArray(filterStore.data) &&
            filterStore?.data?.find((item: IFilterModel) => item.isSelected);
        if (selectedFilter.id === 0) {
            setSelectedFilter({
                ...selectedFilter,
                id: 0,
            });
        } else {
            if (filter) {
                setSelectedFilter(filter);
                setFilterName(filter.name);
            } else {
                resetFilterToInitialValues();
                setFilterName("");
            }
        }
    }, [filterStore.data]);

    const getPageTitle = (): string => {
        const { SentItems, ReceivedItems, DocumentRequest } = appInsightsData.MessageHistory;
        switch (filterType) {
            case FilterType.SIH:
                return SentItems.PageTitle;
            case FilterType.RIH:
                return ReceivedItems.PageTitle;
            case FilterType.DRH:
                return DocumentRequest.PageTitle;
        }
    };
    const onHide = () => {        
        setShowFilterModal(false);
        setShowSaveFilterModal(false);
        clearFilterValidations();
        setEnableClearFilter(false);        
        setResetFilterEnable(false);
        const filter = filterStore.data && filterStore.data?.find((item: IFilterModel) => item.isSelected);
        if (filter) {
            setSelectedFilter(filter);
        } else {
            if (selectedFilter.id === 0) {
                setSelectedFilter({
                    ...prevSelectedFilter,
                    filterSource: filterType,
                });
            } else {
                setSelectedFilter({
                    ...initialFilterValues,
                    filterSource: filterType,
                });
            }
        }
    };

    const handleFilterStateChange = (data: IFilterCondition) => {
        setSelectedFilter({
            ...selectedFilter,
            filterCondition: data,
        });
        setEnableClearFilter(true);
        setResetFilterEnable(true);
    };

    const handleUpdateFilter = (isSelected: boolean, clearFilter?: boolean) => {        
        const filter =
            filterStore && filterStore.data?.length > 0
                ? filterStore.data.find(
                      (item: IFilterModel) => item.id === selectedFilter.id || item.name === selectedFilter.name
                  ) ?? selectedFilter
                : selectedFilter;
        // Save filter api call
        let payload = {
            ...selectedFilter,
            id: filter.id ?? selectedFilter.id,
            isSelected: isSelected,
        };
        if (clearFilter) {
            if (filter.id === 0 && !filter.isSelected) {
                setSelectedFilter(initialFilterValues);
                setFilterName("");
                fetchReportsData();
                return;
            }
            payload = {
                ...filter,
                id: filter.id,
                isSelected: false,
            };
        }
        dispatch(
            updateFilter(payload, getPageTitle(), (status: number) => {
                if (status) {
                    !isSelected &&
                        AppNotifier.Success(
                            clearFilter
                                ? ToasterMessages.SUCCESS.REMOVE_FILTER
                                : ToasterMessages.SUCCESS.UPDATE_FILTER
                        );
                    dispatch(getAllFilters(filterType));
                    clearFilter && setFilterName("");
                    clearFilter && fetchReportsData();
                }                
            })
        );        
    };

    const handleSaveFilter = () => {        
        if (checkIfFilterDataValid()) {
            if (selectedFilter?.name) {
                handleUpdateFilter(false);
            } else {
                setShowSaveFilterModal(true);
            }
        }
    };
    const clearFilterConditions = () => {
        const filter =
            filterStore.data && filterStore.data?.find((item: IFilterModel) => item.name === selectedFilter.name);
        if (filter) {
            setSelectedFilter({
                ...filter,
                id: selectedFilter.id,
            });
            setEnableClearFilter(false);
            setResetFilterEnable(false);
            clearFilterValidations();
        } else {
            resetFilterToInitialValues();
        }
    };

    const clearFilterValidations = () => {
        toggleDateValidation(initialDateValidationObject);
        setActivityDateAccordionValidation(false);
        setSentDateAccordionValidation(false);
        setInvalidSentDateValidation(false);
        setInvalidActivityDateValidation(false);
    };

    const handleCreateFilter = (name: string, isApplied?: boolean) => {
        // Create Api filter call
        const payload = {
            ...selectedFilter,
            name: name.trim(),
            isSelected: isApplied ? true : false,
            filterSource: filterType,
        };
        dispatch(
            createFilter(payload, getPageTitle(), (status: number) => {
                if (status) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.ADD_FILTER);
                    dispatch(getAllFilters(filterType));
                    isApplied && fetchReportsData();
                    isApplied && setShowFilterModal(false);
                    setShowSaveFilterModal(false);
                }
            })
        );
    };

    const handleDeleteFilter = (id: number) => {
        if (id > 0) {
            dispatch(
                deleteFilter(id, (data: any) => {
                    if (data) {
                        AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_FILTER);
                        dispatch(getAllFilters(filterType));
                        resetFilterToInitialValues();
                        selectedFilter.id === 0 && fetchReportsData();
                    }
                })
            );
        }
    };

    const updateDateValidation = (type: string, FilterType: string) => {
        toggleDateValidation(prevState => {
            return {
                ...prevState,
                [FilterType]: {
                    ...prevState[FilterType as keyof IDateValidationObject],
                    [type]: true,
                },
            };
        });
    };

   


    const checkDateFilters = () => {
        let isValid = true;

        if(selectedFilter.filterCondition.sentDate.fromDate === null || selectedFilter.filterCondition.sentDate.toDate === null ){
            setInvalidSentDateValidation(false);
            setSentDateAccordionValidation(true);
            isValid = false;
            selectedFilter.filterCondition.sentDate.fromDate === null && updateDateValidation("fromDate", "sentDate");
            selectedFilter.filterCondition.sentDate.toDate === null && updateDateValidation("toDate", "sentDate");
            return isValid;
        }

        if(selectedFilter.filterCondition.activityLog.fromDate === null || selectedFilter.filterCondition.activityLog.toDate === null ){
            setInvalidActivityDateValidation(false);
            setActivityDateAccordionValidation(true);
            selectedFilter.filterCondition.activityLog.fromDate === null && updateDateValidation("fromDate", "activityLog");
            selectedFilter.filterCondition.activityLog.toDate === null && updateDateValidation("toDate", "activityLog");
            isValid = false;
            return isValid;
        }

        // Convert string dates to Date objects
        const fromDateSent = new Date(selectedFilter.filterCondition.sentDate.fromDate);
        const toDateSent = new Date(selectedFilter.filterCondition.sentDate.toDate);
        const fromDateActivity = new Date(selectedFilter.filterCondition.activityLog.fromDate);
        const toDateActivity = new Date(selectedFilter.filterCondition.activityLog.toDate);


        // Check sentDate validity using the isDateValid function
        if (!isDateValid(fromDateSent) || !isDateValid(toDateSent)) {
            if(fromDateSent.toDateString() !== "Invalid Date" || toDateSent.toDateString() !== "Invalid Date") {
                setSentDateAccordionValidation(false);
                setInvalidSentDateValidation(true);
            }else {
                setInvalidSentDateValidation(true);
                setSentDateAccordionValidation(false);
            }
            !isDateValid(fromDateSent) && updateDateValidation("fromDate", "sentDate");
            !isDateValid(toDateSent) && updateDateValidation("toDate", "sentDate");
            isValid = false;
        }

        // Check activityLog validity using the isDateValid function
        if (!isDateValid(fromDateActivity) || !isDateValid(toDateActivity)) {
            if(fromDateActivity.toDateString() !== "Invalid Date" || toDateActivity.toDateString() !== "Invalid Date") {
                setActivityDateAccordionValidation(false);
                setInvalidActivityDateValidation(true);
            }else {
                setInvalidActivityDateValidation(true);
                setActivityDateAccordionValidation(false);
            }
            !isDateValid(fromDateActivity) && updateDateValidation("fromDate", "activityLog");
            !isDateValid(toDateActivity) && updateDateValidation("toDate", "activityLog");
            isValid = false;
        }

        return isValid;
    };

    

    const checkIfFilterDataValid = () => {
        if (!checkDateFilters()) {
            return false;
        }
        return true;
    };

    const handleApplyFilter = () => {
        setEnableClearFilter(false);
        setResetFilterEnable(false);
        if (checkIfFilterDataValid()) {
            // Apply non saved filter
            handleUpdateFilter(true);
            setSelectedFilter({
                ...selectedFilter,
                id: 0,
            });
            selectedFilter.name ? setFilterName(selectedFilter.name) : setFilterName("");

            fetchReportsData(
                {
                    ...initialSentItemsRequestObject,
                    filterId: 0,
                    filterParameters: selectedFilter.filterCondition,
                },
                () => {
                    if(selectedFilter) {
                        setPrevSelectedFilter(structuredClone({...selectedFilter, id: 0}));            
                    }
                    AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_FILTER);
                    setShowFilterModal(false);
                }
            );
            logger.trackEvent({
                name: `${getPageTitle()} - ${appInsightsData.MessageHistory.Operation.FilterApplied}`,
                properties: {
                    page: getPageTitle(),
                    isSavedFilter: !!selectedFilter.name,
                },
            });
        }
        setCurrentPageNumber(1);
    };

    const handleClearSelectedFilter = () => {
        resetFilterToInitialValues();        
        handleUpdateFilter(false, true);
        handleRefresh();
    };

    const handleRefresh = () => {
        if (selectedFilter.id == 0) {
            // Fetch data with custom filter
            fetchReportsData({
                ...initialSentItemsRequestObject,
                filterId: 0,
                filterParameters: selectedFilter.filterCondition,
            });
        } else {
            // Fetch data with filter id -1 (Backend will check for selected filter. No need to pass filter params)
            fetchReportsData();
            //TO DO: will be removed later
            if (filterType != FilterType.DRH) dispatch(getAllFilters(filterType));
        }

        // Fetch Metrics Data
        if (filterType === FilterType.SIH) {
            dispatch(getSentItemsHistoryMetrics());
            dispatch(fetchSentMessageHistorySettings());
            logger.trackEvent({
                name: `${appInsightsData.MessageHistory.SentItems.PageTitle} - ${appInsightsData.MessageHistory.Operation.TableRefreshed}`,
                properties: {
                    page: appInsightsData.MessageHistory.SentItems.PageTitle,
                },
            });
        }
        if (filterType === FilterType.RIH) {
            dispatch(getReceivedItemsHistoryMetrics());
            dispatch(fetchReceivedMessageHistorySettings());
            logger.trackEvent({
                name: `${appInsightsData.MessageHistory.ReceivedItems.PageTitle} - ${appInsightsData.MessageHistory.Operation.TableRefreshed}`,
                properties: {
                    page: appInsightsData.MessageHistory.SentItems.PageTitle,
                },
            });
        }
        if (filterType === FilterType.DRH) {
            dispatch(getDocumentHistoryMetrics());
            dispatch(fetchRequestHistorySettings());
            logger.trackEvent({
                name: `${appInsightsData.MessageHistory.DocumentRequest.PageTitle} - ${appInsightsData.MessageHistory.Operation.TableRefreshed}`,
                properties: {
                    page: appInsightsData.MessageHistory.DocumentRequest.PageTitle,
                },
            });
        }
    };

    const handleFilterButtonClick = () => {
        // Initial values for filter
        if (selectedFilter.id === -1) {
            resetFilterToInitialValues();
        }
        setShowFilterModal(true);
    };

    const handleClearDropDownFilter = () => {
        resetFilterToInitialValues();
    };

    const resetFilterToInitialValues = () => {
        setSelectedFilter({
            ...initialFilterValues,
            filterSource: filterType,
            userId: userProfile.userId,
            filterCondition: {
                ...initialFilterValues.filterCondition,
                emailAddress: emails,
                transferMode:
                    filterType === FilterType.RIH
                        ? [MethodOfTransfer.DropOff,MethodOfTransfer.FolderDropOff, ...initialFilterValues.filterCondition.transferMode]
                        : [...initialFilterValues.filterCondition.transferMode],
            },
        });
        setActivityDateAccordionValidation(false);
        setSentDateAccordionValidation(false);
        setInvalidSentDateValidation(false);
        setInvalidActivityDateValidation(false);
        toggleDateValidation(initialDateValidationObject);
        setEnableClearFilter(false);
        setResetFilterEnable(false);
    };

    return (
        <div className="report-header-container" id={title} key={filterType}>
            <div className="title-container">
                <h3 data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderTitle}>{title}</h3>
                <div className="actions">
                    <span
                        title="Refresh"
                        className="icon-wrapper"
                        data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderRefreshIcon}
                        onClick={handleRefresh}
                    >
                        <RefreshIcon/>
                    </span>

                    <span
                        className="icon-wrapper"
                        onClick={() => handleColumnDrawer(true)}
                        data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderManCol}
                        title="Manage Columns"
                    >
                        <ManageColumnsIcon />
                    </span>
                    {filterType != FilterType.DRH && (
                        <>
                            <span
                                title="Download"
                                data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderDwldIcon}
                                onClick={() => !isDownloading && handleDownload()}
                                className={`icon-wrapper ${isDownloading ? "disabled" : ""}`}
                            >
                                <DownloadIcon />
                            </span>
                        </>
                    )}

                            <span
                                className="icon-wrapper"
                                onClick={handleFilterButtonClick}
                                data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderFilterIcon}
                                title="Filter"
                            >
                                <FilterIcon/>
                            </span>
                </div>
            </div>
            <div className="report-header">
                {
                    // Hide metrics data based on settings
                    showMetricsData && (
                        <div className="metrics-container">
                            {metricsData.length > 0 &&
                                metricsData.map(metric => (
                                    <div className="metrics-card" key={metric.title + "-" + metric.count}>
                                        <span
                                            className="metrics-title"
                                            data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderMessage}
                                        >
                                            {metric.title}
                                        </span>
                                        <span className="metrics-count">{metric.count}</span>
                                    </div>
                                ))}
                        </div>
                    )
                }
            </div>
            <div className="filter-result-container">
                {filterName.length > 0 && (
                    <span>
                        {ReportConstants.SHOW_SAVED_FILTER_TEXT}
                        <span
                            className="filter-badge"
                            data-test-auto={AutomationIdConstants.commonSihRih.ReportHeaderFilterBadge}
                        >
                            {filterName}
                            <span title="clear" onClick={handleClearSelectedFilter}>
                                <CloseIcon width={11} height={11} color={ColorConstants.white} />
                            </span>
                        </span>
                    </span>
                )}
                {showCustomFilterText && <span>{ReportConstants.SHOW_CUSTOM_FILTER_TEXT}</span>}
            </div>
            <Drawer
                show={showFilterModal}
                title={ReportsFilterConstants.FILTER_MODAL_TITLE}
                confirmButtonName={ReportsFilterConstants.FILTER_MODAL_CONFIRM_BUTTON_NAME}
                cancelButtonName={ReportsFilterConstants.FILTER_MODAL_CANCEL_BUTTON_NAME}
                additionalActionButtonName={ReportsFilterConstants.FILTER_MODAL_ADDITIONAL_BUTTON_NAME}
                onHide={onHide}
                onSubmit={handleSaveFilter}
                onAdditionalButtonClick={handleApplyFilter}
                enableClearFilter={filterType === FilterType.DRH ? enableClearFilter : true}
            >                
                <Filters  
                    showSaveFilterModal={showSaveFilterModal}
                    filterType={filterType}
                    sendersList={sendersList}
                    filters={filterStore}
                    selectedFilter={selectedFilter}
                    dateValidation={dateValidation}
                    sentDateAccordionValidation={sentDateAccordionValidation}
                    activityDateAccordionValidation={activityDateAccordionValidation}
                    invalidSentDateValidation={invalidSentDateValidation}
                    setInvalidSentDateValidation={setInvalidSentDateValidation}
                    invalidActivityDateValidation={invalidActivityDateValidation}
                    setInvalidActivityDateValidation={setInvalidActivityDateValidation}
                    setEnableClearFilter={setEnableClearFilter}
                    resetFilterEnable={resetFilterEnable}
                    setResetFilterEnable={setResetFilterEnable}
                    setActivityDateAccordionValidation={setActivityDateAccordionValidation}
                    setSentDateAccordionValidation={setSentDateAccordionValidation}
                    toggleDateValidation={toggleDateValidation}
                    handleApplyFilter={handleApplyFilter}
                    handleDeleteFilter={handleDeleteFilter}
                    handleCreateFilter={handleCreateFilter}
                    setSelectedFilter={setSelectedFilter}
                    clearFilterConditions={clearFilterConditions}
                    handleClearDropdownFilter={handleClearDropDownFilter}
                    handleFilterStateChange={handleFilterStateChange}
                    hideSaveFilterModal={() => setShowSaveFilterModal(false)}
                />
            </Drawer>
        </div>
    );
};

export default ReportHeader;
